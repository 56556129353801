import Link from 'next/link';
import { StaticImage } from '@/components';

const Custom404 = () => {
  return (
    <div className="errorSec">
      <div className="error error404">
        <StaticImage src="/static/images/layout/error/404.gif" className={'error-player'} />
        <p className="txt1">페이지를 찾을 수 없습니다.</p>
        <p className="txt2">페이지가 존재하지 않거나 사용할 수 없는 페이지 입니다.</p>
        <div className="btSec">
          <Link className="bt bt1" href="/">
            홈으로 이동
          </Link>
        </div>
      </div>
    </div>
  );
};

Custom404.getLayout = function getLayout(page) {
  return <>{page}</>;
};

export default Custom404;
